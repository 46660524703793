<template>
  <!-- 放置二维码的容器,需要给一个ref -->
  <div id="qrcode">扫码评估</div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {};
  },

  mounted() {
    this.qrcode();
  },
  methods: {
    qrcode() {
      let qrcode = new QRCode("qrcode", {
        width: 100,
        height: 100, // 高度
        text: "http://chongqingpsy.cloudervr.com/#/AssessmentLogin", // 二维码内容
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        background: "#f0f",
        foreground: "#ff0",
      });
      // console.log(qrcode);
    },
  },
};
</script>

<style lang='scss'>
#qrcode {
  width: 200px;
  height: 200px;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
